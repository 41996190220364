import React, {useCallback, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {styled} from '@material-ui/styles';
import {Avatar, Button, TextField, Theme, Typography, makeStyles} from '@material-ui/core';
import {StrUtil} from '@front-libs/helpers';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {PinIcon} from '@atoms/Icons/pin';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {DeleteCard} from '@molecules/Cards/DeleteCard';
import {HistoryCardProps} from '@Apps/ProductDetail/Content/Cards/HistoryCard';

const AvatarIcon = styled(Avatar)({
  width: '32px',
  height: '32px',
});

/**
 * コメントカードのProps
 */
export type CommentCardProps = {
  historyCardProps: HistoryCardProps;
  canEditRepair: boolean;
  canDeleteRepair: boolean;
  /**
   * コメントが更新された時に呼び出される関数
   * @param comment - 更新されたコメント。
   */
  onUpdateComment: (comment: string) => void;
  /**
   * ピンどめが更新された時に呼び出される関数
   */
  onUpdatePinned: () => void;

  /**
   * コメントが削除された時に呼び出される関数
   */
  onDeleteComment: () => void;
};

// FIXME: useMyInfoの呼び出しを消す
export const CommentCard = ({
  historyCardProps,
  canEditRepair,
  canDeleteRepair,
  onUpdateComment,
  onUpdatePinned,
  onDeleteComment,
}: CommentCardProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingComment, setEditingComment] = useState(historyCardProps.description ?? '');
  const {myInfo} = useMyInfo();
  const classes = useHistoryCardStyles();

  const updatedAt = useMemo(() => {
    return historyCardProps.updatedAt;
  }, [historyCardProps.updatedAt]);

  const displayDate = useMemo(() => {
    return historyCardProps.displayDate;
  }, [historyCardProps.displayDate]);

  const archivedBy = useMemo(() => {
    return historyCardProps.archivedBy;
  }, [historyCardProps.archivedBy]);

  const isArchived = useMemo(() => {
    return historyCardProps.isArchived;
  }, [historyCardProps.isArchived]);

  const pinned = useMemo(() => {
    return historyCardProps.pinned;
  }, [historyCardProps.pinned]);

  const user = useMemo(() => {
    return historyCardProps.user;
  }, [historyCardProps.user]);

  const comment = useMemo(() => {
    return historyCardProps.description ?? '';
  }, [historyCardProps.description]);

  /** コメントを保存し、編集モードを終了、親コンポーネントに更新を通知する関数 */
  const handleSaveComment = useCallback(() => {
    setIsEditing(false);
    // 編集済み表示判別に利用
    onUpdateComment(editingComment);
  }, [editingComment, onUpdateComment]);

  /** コメントを削除し、編集モードを終了、親コンポーネントに削除を通知する関数 */
  const handleCancelEditComment = useCallback(() => {
    setIsEditing(false);
    setEditingComment(comment);
  }, [comment]);

  /** アクションボタン押下時 */
  const handleActionMenuClick = useCallback(
    async (item: MenuItemType) => {
      switch (item.value) {
        case 'edit':
          setIsEditing(true);
          break;
        case 'pin':
          onUpdatePinned();
          break;
        case 'delete':
          onDeleteComment();
          break;
        default:
          break;
      }
    },
    [onUpdatePinned, onDeleteComment]
  );

  /**
   * アクションボタンを使える権限があるか
   * 自身のコメントもしくは管理者権限があればtrueを返す
   **/
  const canActionButton = useMemo(() => {
    if (!user) return false;
    return user.hashId === myInfo.hashId && canEditRepair;
  }, [canEditRepair, user, myInfo.hashId]);

  /** コメントを編集可能か */
  const isEditableComment = useMemo(() => {
    if (!user) return false;
    return user.hashId === myInfo.hashId && canEditRepair;
  }, [user, myInfo]);

  const actionMenuItems = useMemo(() => {
    const items = [
      {
        label: (
          <MenuItem>
            <PinIcon style={{paddingRight: '8px'}} />
            <MenuItemLabel>{pinned ? 'ピンどめを解除' : 'コメントをピンどめ'}</MenuItemLabel>
          </MenuItem>
        ),
        value: 'pin',
      },
    ];
    if (user?.hashId === myInfo.hashId && canDeleteRepair) {
      items.push({
        label: (
          <MenuItem style={{color: '#C7243A'}}>
            <DeleteIcon style={{paddingRight: '8px'}} />
            <MenuItemLabel>コメントを削除</MenuItemLabel>
          </MenuItem>
        ),
        value: 'delete',
      });
    }
    if (isEditableComment) {
      items.unshift({
        label: (
          <MenuItem>
            <CreateIcon style={{paddingRight: '8px'}} />
            <MenuItemLabel>コメントを編集</MenuItemLabel>
          </MenuItem>
        ),
        value: 'edit',
      });
    }
    return items;
  }, [pinned, user?.hashId, myInfo.hashId, canEditRepair, canDeleteRepair, isEditableComment]);
  if (isArchived && archivedBy) {
    return <DeleteCard displayDate={displayDate ?? ''} updateAt={updatedAt ?? ''} updateBy={archivedBy} width="none" />;
  }

  return (
    <RootContainer>
      <AvatarIcon />
      <CommentContentContainer>
        <CommentContentHeader>
          <UserName>{user ? UserFormatter.getFullName(user) : '不明なユーザー'}</UserName>
          <div style={{display: 'flex'}}>
            {pinned && <PinIcon style={{width: '18px', height: '18px', color: '#2A96E8', padding: '7px'}} />}
            {canActionButton && (
              <PopperMenuButton
                hiddenArrow
                menuItemList={actionMenuItems}
                placement={'bottom-end'}
                buttonProps={{size: 'small', color: 'inherit', style: {minWidth: '14px', marginRight: '8px'}}}
                itemProps={{style: {fontSize: '14px'}}}
                onMenuClick={handleActionMenuClick}>
                <MoreVertIcon />
              </PopperMenuButton>
            )}
            <CommentDate>{dayjs(displayDate).format('MM/DD HH:mm')}</CommentDate>
          </div>
        </CommentContentHeader>
        {isEditing ? (
          <>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              className={classes.commentEditor}
              value={editingComment}
              onChange={(e) => setEditingComment(e.target.value)}
              style={{marginBottom: '16px'}}
            />
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button className={classes.editCommentBtn} style={{marginRight: '8px'}} onClick={handleSaveComment}>
                保存
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCancelEditComment}>
                キャンセル
              </Button>
            </div>
          </>
        ) : (
          <StyledCommentButton disabled fullWidth>
            {StrUtil.nl2br(comment)}
          </StyledCommentButton>
        )}
      </CommentContentContainer>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px 0px',
  padding: '16px',
  backgroundColor: 'white',
});

const CommentContentContainer = styled('div')({
  marginLeft: '8px',
  width: '100%',
});

const CommentContentHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const UserName = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '14px',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
  lineHeight: '32px',
});

const MenuItem = styled('span')({
  display: 'flex',
});

const MenuItemLabel = styled('span')({
  lineHeight: '24px',
});

const StyledCommentButton = styled(Button)(({theme}: {theme: Theme}) => ({
  color: theme.palette.common.black,
  padding: '0',
  boxSizing: 'border-box',
  justifyContent: 'flex-start',
  textAlign: 'left',
  '&.Mui-disabled': {
    color: theme.palette.common.black,
  },
}));

const useHistoryCardStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
  },
  cardHeader: {
    paddingTop: 8,
    paddingLeft: 16,
  },
  actionMenu: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  flex: {
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 700,
  },
  displayDate: {
    color: theme.palette.grey[600],
  },
  propertyContainer: {
    paddingTop: 16,
    paddingLeft: 8,
    paddingBottom: 8,
  },
  property: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.common.black,
  },
  propertyLabel: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.grey[600],
  },
  commentBtnIcon: {
    visibility: 'hidden',
    transition: 'visibility',
    transitionDelay: '100ms',
  },
  commentEditor: {
    '& .MuiOutlinedInput-multiline': {
      padding: '8px 16px',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  cancelBtn: {
    fontSize: '14px',
    borderRadius: '3px',
    border: 'none',
    padding: '4px 16px',
    color: '#42526E',
  },
  editCommentBtn: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    borderRadius: '3px',
    padding: '4px 16px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
}));
